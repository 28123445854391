<script>
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { BCard, BButton, BModal, BFormCheckbox, BFormFile } from 'bootstrap-vue'
import draggable from 'vuedraggable'
export default {
  components: {
    BCard, BButton, BModal, BFormCheckbox, BFormFile, draggable,
  },
  data() {
    return {
      pageArrayStock: [], // for Frontend Search
      pageArray: [],
      addList: [],

      addModal: false,
      addCarModel: {},
      addCarPrice: '',

      editModal: false,
      editObject: {
        brandId: '',
        brandName: '',
        brandImage: {},
        modelLimit: 0,
      },
      editImageView: '',

      filterName: '',

      viewMedia: '',
      mediaModal: false,

      dragList: [],
    }
  },
  async created() {
    await this.mountedData()
  },
  methods: {
    async mountedData() {

      // Clear Variables..
      this.addModal = false
      this.editModal = false
      this.addList = []
      this.addCarPrice = ''

      // Get List
      await this.$http.get('/features/showroom/list')
          .then(response => {
            this.pageArrayStock = this.pageArray = response.data
          }).catch(() => { alert('ERROR! PLEASE CONTACT TO DEVELOPER') })

      // Add List
      await this.$http.get('/features/model/all')
          .then(response => {
            // Response geldikten sonra var olan Liste ile Merge etmem lazım.
            let addCarList = response.data
            addCarList.forEach(element => {
              const filterData = this.pageArray.filter(x => x.modelId === element.modelId)
              if (filterData.length === 0) {
                this.addList.push(element)
              }
            })
          }).catch(() => { alert('ERROR! PLEASE CONTACT TO DEVELEOPER') })
    },
    async tableInteractive(item, index, type) {
      if (type === 'edit') {
        this.editObject = JSON.parse(JSON.stringify(item)) // Referansları koparıyorum.
        this.editModal = true
      }

      if (type === 'active') {
        this.$swal.fire({
          title: 'ÇOK DİKKAT',
          text: 'Showroomdan bu aracı siliyorsunuz. Bu işlemi geri almak için aracı tekrar eklemeniz gerekecektir. Bu süre zarfında oyuncular Showroom üzerinden bu aracı alamayacaklardır.',
          icon: 'error',
          showCancelButton: true,
          cancelButtonText: 'İptal Et',
          cancelButtonColor: '#B72424',
          confirmButtonText: 'Devam Et',
          confirmButtonColor: '#26B724'
        }).then(async response => {
          if (response.isConfirmed) {
            await this.$http.post('features/showroom/remove', { showroomId: item.showroomId })
                .then(() => {
                  this.$toast({
                    component: ToastificationContent,
                    props: {
                      title: 'Success',
                      icon: 'CheckIcon',
                      variant: 'success',
                    },
                  })
                  this.mountedData()
                }).catch(error => {
                  let errorMessage = ''
                  switch (error.response.data) {
                    case 'CannotDeleteYourself' : errorMessage = 'Cannot Delete Yourself'; break;
                    case 'PermissionError' : errorMessage = 'Permission Error!'; break;
                  }
                  this.$toast({
                    component: ToastificationContent,
                    props: {
                      title: errorMessage,
                      icon: 'XIcon',
                      variant: 'danger',
                    },
                  })
                })
          }
        })
      }
    },
    async saveData(type) {
      if (type === 'create') {
        const sendObject = {
          modelId: this.addCarModel.modelId,
          modelPrice: this.addCarPrice
        }

        await this.$http.post('features/showroom/add', sendObject)
            .then(() => {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Success, Please Wait..',
                  icon: 'CheckIcon',
                  variant: 'success',
                },
              })
              this.mountedData()
            }).catch(error => {
              let errorMessage = ''
              switch (error.response.data) {
                case 'BadValidation' : errorMessage = 'Brand Name To Short'; break;
              }
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: errorMessage,
                  icon: 'XIcon',
                  variant: 'danger',
                },
              })
            })
      }

      if (type === 'update') {
        const sendObject = {
          showroomId: this.editObject.showroomId,
          modelPrice: this.editObject.modelPrice,
          modelLimit: this.editObject.modelLimit
        }

        await this.$http.put('features/showroom/update', sendObject)
            .then(() => {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Success, Please Wait..',
                  icon: 'CheckIcon',
                  variant: 'success',
                },
              })
              this.mountedData()
            }).catch(error => {
              let errorMessage = ''
              switch (error.response.data) {
                case 'AdminEmailIsRegistered' : errorMessage = 'Admin Email is Valid'; break;
              }
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: errorMessage,
                  icon: 'XIcon',
                  variant: 'danger',
                },
              })
            })
      }
    },
    deleteImage(type) {
      if (type === 'add') {
        this.addImageView = ''
        this.addObject.brandImage = {}
      } else {
        this.editImageView = ''
        this.editObject.brandImage = {}
      }
    },
    async changeOrder(list) {
      let sendArray = []
      list.forEach((element, index) => {
        sendArray.push({
          showroomId: element.showroomId,
          order: index
        })
      })
      await this.$http.post('features/showroom/order', sendArray)
    }
  },
}
</script>

<template>
  <section class="pb-2">
    <div class="tb-card tb-flex-vertical">
      <a class="tb-page-title">Showroom Cars ({{pageArrayStock.length}})</a>
      <button class="tb-button bg-primary ml-auto" @click="addModal = true">
        <a class="text-white">Add Car Model</a>
      </button>
    </div>
    <div class="tb-card mt-2">
      <table class="tb-table">
        <thead>
        <tr>
          <th style="width: 5%"><input type="text" class="form-control" placeholder="#" disabled readonly></th>
          <th style="width: 20%"><input type="text" class="form-control" placeholder="Brand Name" disabled readonly></th>
          <th style="width: 20%"><input type="text" class="form-control" placeholder="Model Name" disabled readonly></th>
          <th style="width: 30%"><input type="text" class="form-control" placeholder="Model Price" disabled readonly></th>
          <th style="width: 25%"><input type="text" class="form-control" placeholder="Options" disabled readonly></th>
        </tr>
        </thead>
        <draggable tag="tbody" :list="pageArray" v-model="dragList"  @end="changeOrder(pageArray)">
        <tr v-for="(item, index) in pageArray">
          <td><a>{{ index + 1 }}</a></td>
          <td>
            <a>{{ item.brandName }}</a>
          </td>
          <td><a>{{ item.modelName }}</a></td>
          <td><a>{{ item.modelPrice }} BEND</a></td>
          <td>
            <button class="tb-button bg-primary tb-ml-5-px" @click="tableInteractive(item, index, 'edit')">
              <span class="text-white">Edit</span>
            </button>
            <button class="tb-button tb-ml-5-px bg-danger" @click="tableInteractive(item, index, 'active')">
              <span class="text-white">Delete</span>
            </button>
          </td>
        </tr>
        </draggable>
      </table>
    </div>

    <!-- Add Modal -->
    <b-modal v-model="addModal" hide-header hide-footer centered size="lg">
      <div class="row py-1">
        <div class="col-12 text-center">
          <a class="tb-page-title">Add Car Model</a>
          <hr>
        </div>
        <div class="col-12 text-center">
          <label>Showroom'da bulunan araç burada listelenmez. <label class="text-primary">Lütfen devam etmek için bir araç seçin.</label></label>
        </div>
        <div class="col-12 mt-1">
          <label class="text-primary">Lütfen aracın BEND fiyatını belirtiniz. (Virgün değil, Nokta ile ayırınız. Örnek: 1.32)</label>
          <input type="text" class="form-control bg-white" v-model="addCarPrice">
        </div>
        <div class="col-12 mt-2">
          <div class="row">
            <div v-for="(item, index) in addList" class="col-4" :class="index > 2 ? 'mt-1' : ''" @click="addCarModel = item">
              <div class="shadow p-1 text-center tb-hover-pointer" :class="item.modelId === addCarModel.modelId ? 'bg-info' : ''">
                <label class="text-primary">{{item.brand ? item.brand.brandName : ''}}</label>
                <br>
                <label class="text-dark">{{item.modelName}}</label>
              </div>
            </div>
          </div>
        </div>
        <div class="col-12 text-center">
          <hr>
          <b-button variant="outline-primary" class="mt-1" :disabled="!addCarPrice || !addCarModel.modelId" @click="saveData('create')">Add & Close</b-button>
        </div>
      </div>
    </b-modal>

    <!-- Edit Modal -->
    <b-modal v-model="editModal" hide-header hide-footer centered size="lg">
      <div class="row py-1">
        <div class="col-12 text-center">
          <a class="tb-page-title">Update Showroom Car</a>
          <hr>
        </div>
        <div class="col-6">
          <label class="text-primary">Aracın Markası</label>
          <input type="text" class="form-control" disabled readonly v-model="editObject.brandName">
        </div>
        <div class="col-6">
          <label class="text-primary">Model Name</label>
          <input type="text" class="form-control" disabled readonly v-model="editObject.modelName">
        </div>
        <div class="col-6 mt-2">
          <label class="text-primary">BEND fiyatı</label>
          <input type="text" class="form-control" v-model="editObject.modelPrice">
        </div>
        <div class="col-6 mt-2">
          <label class="text-primary">Satılacak Limit</label>
          <input type="text" class="form-control" v-model="editObject.modelLimit">
        </div>
        <div class="col-12 text-center">
          <hr>
          <b-button variant="outline-primary" class="mt-1" :disabled="!editObject.modelPrice" @click="saveData('update')">Update & Close</b-button>
        </div>
      </div>
    </b-modal>

    <!-- Media Modal -->
    <b-modal v-model="mediaModal" hide-header hide-footer centered size="lg">
      <div class="row py-1">
        <div class="col-12 text-center">
          <img :src="viewMedia" class="w-100 img-fluid" alt="">
        </div>
      </div>
    </b-modal>

  </section>
</template>
